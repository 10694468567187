<!--
 * @Author: zyf493 1799563585@qq.com
 * @Date: 2022-06-22 11:50:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-04-12 11:40:27
 * @FilePath: /dataview-viewer-test/src/views/newsShare/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="container" v-loading="loading">
    <div class="main" v-if="Object.keys(dataInfo).length !== 0">
      <div class="title">
        {{ dataInfo.caption ? dataInfo.caption : '' }}
      </div>
      <div class="info">
        <div>发布时间：{{ dataInfo.created_at ? dataInfo.created_at.slice(0, 10) : "" }}</div>
        <div>栏目：{{ dataInfo.programa_show ? dataInfo.programa_show : "" }}</div>
        <div>作者：{{ dataInfo.author_name ? dataInfo.author_name : "" }}</div>
      </div>
      <div v-if="dataInfo.text" class="content" v-html="dataInfo.text" />
    </div>
  </div>
</template>
<script>
import { dataInterface } from '@/apis/data'
export default {
	name: 'newsRelease',

	data() {
		return {
			loading:false,
      dataInfo:{}
		};
	},
  created(){
    this.getData()
  },
	methods: {
    getData(){
      this.loading = true
      const obj = {
        object_uuid: 'object628eed17e8ef3',
        view_uuid: 'view62aa963511d05',
        __method_name__: 'dataInfo',
        transcode: 0,
        data_id: Number(this.$route.query.id)
      }
      dataInterface(obj).then(res => {
        if (res.data.code === 200) {
          this.dataInfo = res.data.data
        }
        this.loading = false
      })
    },
	}
};
</script>
<style lang="less" scoped>
.container{
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  .main{
    width: 64%;
    height: 100%;
    padding: 10px 20px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow-y: auto;
    .title{
      height: 60px;
      font-size: 30px;
      padding-bottom: 10px;
      font-weight: 500;
      color: rgb(199, 6, 6);
      line-height: 60px;
      text-align: center;
      border-bottom: 1px solid #aaa;
    }
    .info{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      margin: 20px 0;
    }
  }
}
</style>
